// COLORS
// $mainRed: #7c0b0f;
// $mainRed: #af0404;
$mainRed: #a80505;
$mainBlue: #3483bd;
$textGrey: #4f5256;
$borderGrey: #bdbdbd;

//FONTS
$hFont: 'Roboto', sans-serif;
$gFont: 'BenderBold';
$mainFont: 'BenderRegular';

//SIZE
$xs: '767px';
$xl: '1380px';
