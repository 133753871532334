.cart-modal {
  &__title {
    font-size: 2.5rem;
  }

  .close {
    font-size: 3.5rem;
    color: $mainRed;
    opacity: .5;
  }

  &__body-header {
    font-size: 2rem;
  }

  &__body-text {
    font-size: 1.8rem;
  }
}
