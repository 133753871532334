.items-table-container {
  font-family: $hFont;

  .items-tables__price {
    color: $mainRed;
    font-weight: 500;
  }

  .item-table-row td {
    vertical-align: middle;
  }

  .items-table__title {
    width: 40%;
  }

  .item-table__btn {
    font-size: 1.1rem;
    background-color: #fff;
    text-transform: uppercase;
    border: 1px solid $mainRed;
    border-radius: 3px;
    color: $mainRed;
    transition: all .3s;
    display: inline-block;
    width: 80px;
  }

  .item-table__btn:hover {
    color: #fff;
    background-color: $mainRed;
  }

  .item-table__btn--blue {
    border: 1px solid $mainBlue;
    color: $mainBlue;
  }

  .item-table__btn--blue:hover {
    color: #fff;
    background-color: $mainBlue;
  }
}
