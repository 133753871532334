.hero {
  box-sizing: border-box;
  width: 96%;
  margin: 5px auto 0 auto;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);

  .hero__link {
    color: #fff;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
  }

  &__icons {
    position: absolute;
    top: 15px;
    right: 50px;

    &-cart {
      height: 44px;
      margin-left: 20px;
    }

    &-vk {
      height: 50px;
    }
  }

  &__cart {
    display: inline-block;
    position: relative;

    .badge {
      position: absolute;
      background-color: $mainRed;
      font-family: $hFont;
      top: 0;
      right: -5px;
    }
  }

  .st {
    font-family: 'PhageRegularKG';
  }
}
