.suppliers-content {
  padding: 20px;
  font-size: 18px;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.suppliers-content__li {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #a80505;
  line-height: 40px;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.suppliers-content__contact {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .suppliers-content__contact {
    margin: 0;
    text-align: center;
  }
}
