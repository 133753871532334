@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic');

@font-face {
  font-family: "PhageRegularKG";
  src: url("/fonts/PhageRegularKG/PhageRegularKG.eot");
  src: url("/fonts/PhageRegularKG/PhageRegularKG.eot?#iefix")format(
        "embedded-opentype"
      ),
    url("/fonts/PhageRegularKG/PhageRegularKG.woff") format("woff"),
    url("/assets/fonts/PhageRegularKG/PhageRegularKG.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "BenderRegular";
  src: url("/fonts/BenderRegular/BenderRegular.eot");
  src: url("/fonts/BenderRegular/BenderRegular.eot?#iefix")format(
        "embedded-opentype"
      ),
    url("/fonts/BenderRegular/BenderRegular.woff") format("woff"),
    url("/fonts/BenderRegular/BenderRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "BenderBold";
  src: url("/fonts/BenderBold/BenderBold.eot");
  src: url("/fonts/BenderBold/BenderBold.eot?#iefix")format(
        "embedded-opentype"
      ),
    url("/fonts/BenderBold/BenderBold.woff") format("woff"),
    url("/fonts/BenderBold/BenderBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
