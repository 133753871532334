
// .container {
//   width: auto;
//
//   padding: 0 15px;
// }




button.link {
  background: none;
  border: none;
  margin: 0;
  padding: 3px;
  outline: none;
  outline-offset: 0;
  color: #fff;
}
