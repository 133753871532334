// @define-mixin atXS {
//   @media (max-width: 768px) {
//     @mixin-content;
//   }
// }

// @define-mixin atSmall {
//   @media (min-width: 530px) {
//     @mixin-content;
//   }
// }
//
// @define-mixin atMedium {
//   @media (min-width: 800px) {
//     @mixin-content;
//   }
// }
//
// @define-mixin atLarge {
//   @media (min-width: 1010px) {
//     @mixin-content;
//   }
// }
//
// @define-mixin atXL {
//   @media (min-width: 1300px) {
//     @mixin-content;
//   }
// }

@define-mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
