.sidebar {
  margin-top: 30px;
  border: 1px solid $borderGrey;
  min-width: 230px;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);
  border-radius: 5px;
  padding-bottom: 20px;

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    color: #fff;
  }

  &__header {
    width: 100%;
    text-align: center;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  &__special-offer {
    font-family: $hFont;
    background-color: $mainRed;
    color: #fff;
    border: 1px solid $mainRed;
    border-radius: 4px;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 10px auto 4px auto;
    width: 218px;
    height: 44px;
    padding: 4px 5px 6px 5px;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 1.9rem;

    span {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__special-offer:hover {
    box-shadow: 0px 2px 5px 0 rgba(51, 51, 51, 0.75);
    background-color: $mainRed;
    color: #fff;
  }

  &__category {
    font-family: $hFont;
    font-weight: 500px;
    font-size: 1.25rem;
    margin: 4px auto;
    letter-spacing: 0.5px;
    line-height: 1.9rem;
    width: 218px;
    height: 44px;
    padding: 0 6px;
    background-color: #fff;
    border: 1px solid $mainRed;
    border-radius: 4px;
    color: $mainRed;
    text-align: center;
    transition: all 0.4s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;

    div {
      height: 100%;
      position: relative;
    }

    span {
      display: block;
      position: absolute;

      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__category:hover {
    box-shadow: 0px 2px 5px 0 rgba(51, 51, 51, 0.75);
  }

  &__subs &__category {
    font-size: 1.2rem;
    margin: 4px auto;
    letter-spacing: 0.5px;
    line-height: 1.9rem;
    width: 200px;
    height: 35px;
    position: relative;
    left: 8px;
  }

  &__subs {
    opacity: 1;
    transition: all .3s;
    height: auto;
  }

  .subs__hidden {
    display: none;
  }

  .active {
    box-shadow: 0px 4px 5px 0 rgba(51, 51, 51, 0.75);
    background-color: $mainRed;
    color: #fff;
  }
}

@media (min-width: $xl) {
  .sidebar {
    &__special-offer {
      font-size: 1.3rem;

      margin: 10px 50px;
    }

    &__category {
      font-size: 1.2rem;
      margin: 5px 50px;
    }
  }
}

@media (max-width: $xs) {
  .sidebar {
    padding-bottom: 10px;
    height: 58px;
    overflow: hidden;

    &--xs-visble {
      height: auto;
    }

    &__header {
      font-size: 2.5rem;
      margin: 15px 0;

      .glyphicon {
        display: inline-block !important;
        font-size: 1.8rem;
        padding-left: 8px;
      }
    }

    &__category,
    &__special-offer {
      font-size: 1.65rem;
      margin: 0px 50px;
    }
  }
}
