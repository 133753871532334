.catalog {
  padding-bottom: 5px;
  margin-top: 15px;
  // height: 58px;
  height: auto;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  user-select: none;
  position: relative;
  transition: opacity 0.5s;

  a,
  a:hover,
  a:focus {
    text-decoration: none;
    color: #fff;
  }

  &__category {
    font-family: $gFont;
    padding: 4px 5px 6px 5px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__special-offer {
    font-family: $gFont;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 4px 5px 6px 5px;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
  }

  &__category,
  &__special-offer {
    font-size: 1.85rem;
    margin: 8px 50px;
    color: #fff;
  }

  .active {
    color: white;
    background-color: $mainRed;
  }

  &__header {
    color: #fff;
    font-size: 1.9rem;
    margin: 15px 0;

    .glyphicon {
      display: inline-block !important;
      font-size: 1.8rem;
      padding-left: 8px;
    }
  }

  &__items {
    height: 66vh;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 3px;
    margin-bottom: 10px;
    position: relative;
    // overflow-y: hidden;
    overflow-y: scroll;
  }

  // &--xs-visble {
  //   height: 200px;
  //   border-bottom: 1px solid rgba(255, 255, 255, .5);
  //   overflow: scroll;
  // }
}
