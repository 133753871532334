div.category-content {
  // width: 96%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  // overflow: hidden;
}

.special-offer-list {
  background-color: $mainRed;
}

.special-offer-list-row .content__heading {
  color: #fff !important;
  margin: 40px auto;
}

div.category_item {
  // background-color: $mainRed;
  border: 2px solid $mainRed;

  .item__title--sublist {
    color: $mainRed;
    font-family: $hFont;
    font-weight: normal;
  }
}

.link-to-special-offer {
  position: relative;
  font-family: $hFont;
  text-transform: uppercase;
  font-size: 18px;

  height: 500px;
}

.special-offer-list-row {
  margin: 0 !important;
}

.link-to-special-offer span {
  display: block;
  text-align: center;
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: $mainRed;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s;
}

.link-to-special-offer span:hover {
  background-color: $mainRed;
  color: #fff;
}

@media (max-width: $xs) {
  div.category_item {
    background-color: $mainRed;

    .item__title--sublist {
      font-size: 24px;
      color: #fff;
    }
  }
}
