footer {
  margin-top: 40px;

  .map {
    width: 100%;
    height: 400px;
    border-top: 2px solid $textGrey;
  }

  .footer {
    width: 100%;
    height: auto;
    background-color: $textGrey;
    color: #fff;

    &__adress {
      margin-top: 0;
      padding-top: 15px;
      margin-left: 15px;
      font-size: 2rem;
    }

    &__terms {
      width: 50%;
      margin-left: 15px;
      margin-bottom: 0;
      padding-bottom: 15px;
      line-height: 1.1rem;
      font-size: 1.3rem;
    }

    &__copyright {
      font-size: 2rem;
      float: right;
      margin: 0;
      padding-top: 55px;
      padding-right: 100px;
      white-space: nowrap;
    }

    &__phone {
      white-space: nowrap;
    }
  }
}

@media (max-width: $xs) {
  footer {
    .footer {
      &__terms {
        width: 100%;
        margin: 0;
        padding: 10px;
      }

      &__copyright {
        float: none;
        font-size: 1.5rem;
        padding: 10px;
      }

      &__adress {
        font-size: 1.5rem;
        padding: 10px;
        margin: 0;
        width: 80%;
      }
    }
  }
}
