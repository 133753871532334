.cart {
  padding: 15px;

  h6 {
    font-family: $mainFont;
    color: $textGrey;
    font-size: 16px;
  }

  .cart__price {
    font-family: $gFont;
    margin-top: 15px;
    margin-bottom: 0;

    span {
      color: $mainRed;
    }
  }

  .panel {
    border-radius: 0;
  }

  &__qty {
    font-family: $gFont !important;
    span {
      display: inline-block;
      margin-left: 11px;
      color: $mainRed;
      font-family: $gFont;
      font-size: 18px;
    }
  }

  &__btn-qty, &__btn-qty:hover {
    color: $mainRed;
    border-color: $mainRed;
    outline: none;
    outline-offset: 0;
    margin: 0 7px;
    border-radius: 0;
  }

  .btn-danger {
    font-family: $gFont;
    margin-top: 10px;
    background-color: $mainRed;
    border-color: $mainRed;
    border-radius: 0;
  }

  &__btn-order {
    font-family: $hFont;
    font-size: 2rem;
    line-height: 1.95rem;
    background: none;
    margin: 10px 0;
    padding: 8px 16px 4px 16px;
    outline: none;
    outline-offset: 0;
    border: 1px solid $mainRed;
    color: $mainRed;
    display: block;
    margin-top: 25px;
  }

  &__sum {
    font-family: $gFont !important;
    font-size: 20px !important;
    margin-bottom: 25px;

    span {
      color: $mainRed;
    }
  }

  // &__sum::after {
  //   content: "";
  //   clear: both;
  //   display: table;
  // }

  &__phone-form {
    border: none;
    // font-family: $gFont;
    font-weight: $mainFont !important;
    display: block;
    font-size: 3rem;
    font-weight: normal;
    // color: $mainBlue;
  }

  &__phone-label {
    display: block;
    font-weight: $mainFont !important;
    font-size: 2rem;
    font-weight: normal;
  }

  &__order {
    border: 1px solid $mainRed;
    padding: 10px;
    width: 400px;
  }

  &--empty {
    margin: 25px;
    color: $textGrey;
    font-family: $gFont;
    font-size: 2rem;
  }
}

@media (max-width: $xs) {
  .cart {
    .panel {
      transform: scale(.95);
      text-align: center;
      position: relative;
    }

    &__order {
      max-width: 100%;
    }

    &__phone-form {
      max-width: 100%;
      font-size: 2.3rem;
    }

    &__phone-label {
      font-size: 1.8rem;
    }

    &__btn--delete {
      display: none;
    }

    &__qty {
      span {
        display: block;
        margin-left: 0;
        margin-top: 10px;
        font-size: 2.5rem;
      }
    }

    &__btn-qty {
      font-size: 2.5rem;
      width: 60px;
    }

    &__delete-badge {
      background-color: $mainRed;
      color: #fff;
      font-size: 1.8rem;
      font-family: $gFont;
      width: 100px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &__sum-bage {
      background-color: $mainRed;
      font-family: $gFont;
      color: #fff;
      position: fixed;
      z-index: 100;
      bottom: 30px;
      left: 10px;
      padding: 10px;
      opacity: .7
    }
  }
}
