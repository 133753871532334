.item {
  border: 1px solid $borderGrey;
  border-radius: 5px;
  width: 96%;
  margin: 30px auto;
  padding: 0 10px 20px 10px;
  text-align: center;
  position: relative;
  transition: all 0.4s;

  &__title {
    font-family: $mainFont;
    color: $textGrey;
    font-size: 1.65rem;
    height: 6.8rem;

    &--sublist {
      font-size: 2.7rem;
    }
  }

  &__price {
    font-size: 1.6rem;
    font-family: $gFont;
    color: $textGrey;

    span {
      color: $mainRed;
    }
  }

  &__dimensions {
    font-family: $mainFont;
    color: $textGrey;
    font-size: 1.5rem;

    &-units {
      font-size: 1.4rem;
    }
  }

  &-btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 15px;
  }

  &__btn {
    font-family: $hFont;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.85rem;
    background-color: #fff;
    margin: 10px 5px;
    padding: 8px 16px 4px 16px;
    outline: none;
    outline-offset: 0;
    text-transform: uppercase;
    border-radius: 5px;
    position: relative;
    width: 108px;
    height: 46px;
    transition: all 0.5s;

    span {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--red {
      border: 1px solid $mainRed;
      color: $mainRed;
    }

    &--red:hover {
      color: #fff;
      background-color: $mainRed;
    }

    &--blue {
      border: 1px solid $mainBlue;
      color: $mainBlue;
    }

    &--blue:hover {
      color: #fff;
      background-color: $mainBlue;
    }
  }

  // &__img {
  //   height: 210px;
  //   margin: 0 auto;
  // }

  &__img-contaner {
    width: 258;
    height: 210px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    transform: scale(0.9);
    transition: all 0.4s;
  }

  &__badge {
    font-family: $hFont;
    font-size: 14px;
    letter-spacing: 0.3px;
    border-radius: 5px;
    display: block;
    text-transform: uppercase;
    color: #fff;
    width: 120px;
    position: absolute;
    top: 20px;
    right: 15px;
  }

  &__cart-badge {
    background-color: $mainBlue;
    padding: 4px 5px 2px 5px;
    width: auto;
    float: right;
  }

  &__action-badge {
    float: right;
    background-color: $mainRed;
    padding: 4px 5px 2px 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 74px;
  }
}

@media (min-width: $xl) {
  .item {
    width: 306px;
  }
}

@media (min-width: $xs) {
  .item:hover .item__img-contaner {
    transform: scale(0.95);
  }

  .item:hover {
    box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);
  }
}

@media (max-width: $xs) {
  .item {
    margin: 5px 0;

    &__img-contaner {
      height: 140px;
    }

    &__badge {
      font-size: 14px;
    }

    &__title {
      font-size: 1.5rem;
      height: 5.1rem;
    }

    &__dimensions {
      font-size: 1.5rem;
    }

    &__price {
      font-size: 1.6rem;
    }

    &__btn {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
}
