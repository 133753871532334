.content {
  margin-top: 30px;
  border: 1px solid $borderGrey;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);
  border-radius: 5px;
  overflow: hidden;

  &__heading {
    text-align: center;
    margin: 20px 0;
    font-weight: 700;
    text-transform: uppercase;
  }
}

@media (max-width: $xs) {
  .content {
    margin-top: 140px;

    &__heading {
      font-size: 2.5rem;
      margin: 7px 0 0 0;
    }
  }
}
