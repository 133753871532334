.sort-container {
  font-family: $hFont;
  font-size: 1.7rem;
  margin: 15px auto;

  border: 1px solid $borderGrey;
  border-radius: 4px;
  padding: 10px 10px 15px 10px;
  color: #626262;

  .text {
    font-weight: 400;
  }

  .sort-btn {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border: 1px solid #939599;
    font-family: $hFont;
    font-weight: 400;
    border-radius: 3px;
    margin-left: 8px;
    top: 9px;
    position: relative;
    width: 70px;
    height: 30px;
    color: #939599;
    font-size: 1.5rem;
    user-select: none;

    span {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -10px);
    }

    .sort-arrow {
      font-size: 2rem;
      position: relative;
      top: -1px;
      left: 2px;
    }
  }

  .sort-btn.active {
    border: 1px solid $mainRed;
    color: $mainRed;
  }

  .sort-btn__long {
    width: 110px;
  }
}
