.poduct-info {
  padding-top: 15px;
  padding-bottom: 15px;
}

.poduct-info-top {
  padding-top: 15px;
  .row {
    margin: 10px;
  }

  .poduct-info-img {
    width: 80%;
    max-width: 350px;
    margin: auto;
    border: 1px solid $borderGrey;
    border-radius: 4px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .poduct-info-images {
    list-style: none;
    margin: 15px auto;
    text-align: center;
    padding: 0;

    li {
      display: inline-block;
      width: 90px;
      height: 90px;
      border: 1px solid $borderGrey;
      border-radius: 3px;
      overflow: hidden;
      margin: 3px;
      cursor: pointer;
      transition: all 0.4s;
    }

    li.active-img {
      border: 1px solid $mainRed;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  text-align: center;

  h2 {
    text-transform: uppercase;
  }

  .product-info-price {
    font-family: $gFont;
    color: #fff;
    font-size: 22px;
    width: 170px;
    border: 1px solid $borderGrey;
    border-radius: 4px;
    padding: 8px;
    margin: 10px auto;
    background-color: $mainRed;
    // font-weight: bold;

    h2 {
      text-transform: uppercase;
      font-family: $hFont;
      color: #fff;
      margin: 0 auto;

      span {
        font-size: 20px;
      }
    }
  }

  h3 {
    color: $textGrey;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  table td {
    font-size: 16px;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  hr {
    width: 90%;
  }

  .product-info-description-heading {
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .product-info-description {
    width: 90%;
    margin: auto;
    text-align: left;
    text-indent: 18px;
    font-size: 18px;
  }

  // button {
  //   background-color: #fff;
  //   color: $mainRed;
  //   border: 2px solid $mainRed;
  //   font-weight: normal;
  //   font-size: 18px;
  //   border-radius: 3px;
  //   margin-top: 15px;
  // }
  // display: flex;
}
