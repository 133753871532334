@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic);
.suppliers-content {
  padding: 20px;
  font-size: 18px; }
  .suppliers-content div {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }

.suppliers-content__li {
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #a80505;
  line-height: 40px;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0; }

.suppliers-content__contact {
  margin-left: 50px; }

@media (max-width: 767px) {
  .suppliers-content__contact {
    margin: 0;
    text-align: center; } }

@font-face {
  font-family: "PhageRegularKG";
  src: url("/fonts/PhageRegularKG/PhageRegularKG.eot");
  src: url("/fonts/PhageRegularKG/PhageRegularKG.eot?#iefix") format("embedded-opentype"), url("/fonts/PhageRegularKG/PhageRegularKG.woff") format("woff"), url("/assets/fonts/PhageRegularKG/PhageRegularKG.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "BenderRegular";
  src: url("/fonts/BenderRegular/BenderRegular.eot");
  src: url("/fonts/BenderRegular/BenderRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/BenderRegular/BenderRegular.woff") format("woff"), url("/fonts/BenderRegular/BenderRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "BenderBold";
  src: url("/fonts/BenderBold/BenderBold.eot");
  src: url("/fonts/BenderBold/BenderBold.eot?#iefix") format("embedded-opentype"), url("/fonts/BenderBold/BenderBold.woff") format("woff"), url("/fonts/BenderBold/BenderBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

button.link {
  background: none;
  border: none;
  margin: 0;
  padding: 3px;
  outline: none;
  outline-offset: 0;
  color: #fff; }

html,
body {
  font-family: "BenderRegular"; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: #a80505; }

.container {
  margin: 0;
  width: 100%; }

.modal-content {
  border-radius: 0; }

@media (max-width: 767px) {
  .padding-at-xs {
    margin-top: 50px; } }

.content-container {
  width: 96%;
  margin: 0 auto; }

.main-category-container {
  margin: 0 auto;
  padding: 0 15px; }

a:hover {
  text-decoration: none !important; }

@define-mixin clearfix {
  ::after {
    content: "";
    clear: both;
    display: table; } }

.hero {
  box-sizing: border-box;
  width: 96%;
  margin: 5px auto 0 auto;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75); }
  .hero .hero__link {
    color: #fff;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none; }
  .hero__icons {
    position: absolute;
    top: 15px;
    right: 50px; }
    .hero__icons-cart {
      height: 44px;
      margin-left: 20px; }
    .hero__icons-vk {
      height: 50px; }
  .hero__cart {
    display: inline-block;
    position: relative; }
    .hero__cart .badge {
      position: absolute;
      background-color: #a80505;
      font-family: "Roboto", sans-serif;
      top: 0;
      right: -5px; }
  .hero .st {
    font-family: 'PhageRegularKG'; }

.sidebar {
  margin-top: 30px;
  border: 1px solid #bdbdbd;
  min-width: 230px;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);
  border-radius: 5px;
  padding-bottom: 20px; }
  .sidebar a,
  .sidebar a:hover,
  .sidebar a:focus {
    text-decoration: none;
    color: #fff; }
  .sidebar__header {
    width: 100%;
    text-align: center;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700; }
  .sidebar__special-offer {
    font-family: "Roboto", sans-serif;
    background-color: #a80505;
    color: #fff;
    border: 1px solid #a80505;
    border-radius: 4px;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 10px auto 4px auto;
    width: 218px;
    height: 44px;
    padding: 4px 5px 6px 5px;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 1.9rem; }
    .sidebar__special-offer span {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .sidebar__special-offer:hover {
    box-shadow: 0px 2px 5px 0 rgba(51, 51, 51, 0.75);
    background-color: #a80505;
    color: #fff; }
  .sidebar__category {
    font-family: "Roboto", sans-serif;
    font-weight: 500px;
    font-size: 1.25rem;
    margin: 4px auto;
    letter-spacing: 0.5px;
    line-height: 1.9rem;
    width: 218px;
    height: 44px;
    padding: 0 6px;
    background-color: #fff;
    border: 1px solid #a80505;
    border-radius: 4px;
    color: #a80505;
    text-align: center;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative; }
    .sidebar__category div {
      height: 100%;
      position: relative; }
    .sidebar__category span {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .sidebar__category:hover {
    box-shadow: 0px 2px 5px 0 rgba(51, 51, 51, 0.75); }
  .sidebar__subs .sidebar__category {
    font-size: 1.2rem;
    margin: 4px auto;
    letter-spacing: 0.5px;
    line-height: 1.9rem;
    width: 200px;
    height: 35px;
    position: relative;
    left: 8px; }
  .sidebar__subs {
    opacity: 1;
    -webkit-transition: all .3s;
    transition: all .3s;
    height: auto; }
  .sidebar .subs__hidden {
    display: none; }
  .sidebar .active {
    box-shadow: 0px 4px 5px 0 rgba(51, 51, 51, 0.75);
    background-color: #a80505;
    color: #fff; }

@media (min-width: 1380px) {
  .sidebar__special-offer {
    font-size: 1.3rem;
    margin: 10px 50px; }
  .sidebar__category {
    font-size: 1.2rem;
    margin: 5px 50px; } }

@media (max-width: 767px) {
  .sidebar {
    padding-bottom: 10px;
    height: 58px;
    overflow: hidden; }
    .sidebar--xs-visble {
      height: auto; }
    .sidebar__header {
      font-size: 2.5rem;
      margin: 15px 0; }
      .sidebar__header .glyphicon {
        display: inline-block !important;
        font-size: 1.8rem;
        padding-left: 8px; }
    .sidebar__category, .sidebar__special-offer {
      font-size: 1.65rem;
      margin: 0px 50px; } }

.content {
  margin-top: 30px;
  border: 1px solid #bdbdbd;
  box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75);
  border-radius: 5px;
  overflow: hidden; }
  .content__heading {
    text-align: center;
    margin: 20px 0;
    font-weight: 700;
    text-transform: uppercase; }

@media (max-width: 767px) {
  .content {
    margin-top: 140px; }
    .content__heading {
      font-size: 2.5rem;
      margin: 7px 0 0 0; } }

.item {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 96%;
  margin: 30px auto;
  padding: 0 10px 20px 10px;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }
  .item__title {
    font-family: "BenderRegular";
    color: #4f5256;
    font-size: 1.65rem;
    height: 6.8rem; }
    .item__title--sublist {
      font-size: 2.7rem; }
  .item__price {
    font-size: 1.6rem;
    font-family: "BenderBold";
    color: #4f5256; }
    .item__price span {
      color: #a80505; }
  .item__dimensions {
    font-family: "BenderRegular";
    color: #4f5256;
    font-size: 1.5rem; }
    .item__dimensions-units {
      font-size: 1.4rem; }
  .item-btn-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 15px; }
  .item__btn {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.85rem;
    background-color: #fff;
    margin: 10px 5px;
    padding: 8px 16px 4px 16px;
    outline: none;
    outline-offset: 0;
    text-transform: uppercase;
    border-radius: 5px;
    position: relative;
    width: 108px;
    height: 46px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    .item__btn span {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .item__btn--red {
      border: 1px solid #a80505;
      color: #a80505; }
    .item__btn--red:hover {
      color: #fff;
      background-color: #a80505; }
    .item__btn--blue {
      border: 1px solid #3483bd;
      color: #3483bd; }
    .item__btn--blue:hover {
      color: #fff;
      background-color: #3483bd; }
  .item__img-contaner {
    width: 258;
    height: 210px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-transition: all 0.4s;
    transition: all 0.4s; }
  .item__badge {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    letter-spacing: 0.3px;
    border-radius: 5px;
    display: block;
    text-transform: uppercase;
    color: #fff;
    width: 120px;
    position: absolute;
    top: 20px;
    right: 15px; }
  .item__cart-badge {
    background-color: #3483bd;
    padding: 4px 5px 2px 5px;
    width: auto;
    float: right; }
  .item__action-badge {
    float: right;
    background-color: #a80505;
    padding: 4px 5px 2px 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    width: 74px; }

@media (min-width: 1380px) {
  .item {
    width: 306px; } }

@media (min-width: 767px) {
  .item:hover .item__img-contaner {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  .item:hover {
    box-shadow: 0px 5px 5px 0 rgba(51, 51, 51, 0.75); } }

@media (max-width: 767px) {
  .item {
    margin: 5px 0; }
    .item__img-contaner {
      height: 140px; }
    .item__badge {
      font-size: 14px; }
    .item__title {
      font-size: 1.5rem;
      height: 5.1rem; }
    .item__dimensions {
      font-size: 1.5rem; }
    .item__price {
      font-size: 1.6rem; }
    .item__btn {
      font-size: 1.5rem;
      line-height: 1.5rem; } }

footer {
  margin-top: 40px; }
  footer .map {
    width: 100%;
    height: 400px;
    border-top: 2px solid #4f5256; }
  footer .footer {
    width: 100%;
    height: auto;
    background-color: #4f5256;
    color: #fff; }
    footer .footer__adress {
      margin-top: 0;
      padding-top: 15px;
      margin-left: 15px;
      font-size: 2rem; }
    footer .footer__terms {
      width: 50%;
      margin-left: 15px;
      margin-bottom: 0;
      padding-bottom: 15px;
      line-height: 1.1rem;
      font-size: 1.3rem; }
    footer .footer__copyright {
      font-size: 2rem;
      float: right;
      margin: 0;
      padding-top: 55px;
      padding-right: 100px;
      white-space: nowrap; }
    footer .footer__phone {
      white-space: nowrap; }

@media (max-width: 767px) {
  footer .footer__terms {
    width: 100%;
    margin: 0;
    padding: 10px; }
  footer .footer__copyright {
    float: none;
    font-size: 1.5rem;
    padding: 10px; }
  footer .footer__adress {
    font-size: 1.5rem;
    padding: 10px;
    margin: 0;
    width: 80%; } }

.sticky {
  width: 100%;
  height: auto;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 0 50px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .sticky--hidden {
    width: 100%;
    height: 75px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    padding: 0 50px; }
  .sticky__logo {
    float: left;
    height: 75px; }
  .sticky__address {
    color: #fff;
    margin: 4px auto;
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: 0.1rem; }

@media (max-width: 767px) {
  .sticky {
    padding: 0 10px; }
    .sticky__address {
      font-size: 1.2rem;
      line-height: 1.4rem;
      width: 200px;
      margin-top: 17px; }
    .sticky__logo {
      height: 50px;
      margin-top: 15px; }
  .hero__icons {
    right: 20px !important;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

.catalog {
  padding-bottom: 5px;
  margin-top: 15px;
  height: auto;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }
  .catalog a,
  .catalog a:hover,
  .catalog a:focus {
    text-decoration: none;
    color: #fff; }
  .catalog__category {
    font-family: "BenderBold";
    padding: 4px 5px 6px 5px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer; }
  .catalog__special-offer {
    font-family: "BenderBold";
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 4px 5px 6px 5px;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer; }
  .catalog__category, .catalog__special-offer {
    font-size: 1.85rem;
    margin: 8px 50px;
    color: #fff; }
  .catalog .active {
    color: white;
    background-color: #a80505; }
  .catalog__header {
    color: #fff;
    font-size: 1.9rem;
    margin: 15px 0; }
    .catalog__header .glyphicon {
      display: inline-block !important;
      font-size: 1.8rem;
      padding-left: 8px; }
  .catalog__items {
    height: 66vh;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 3px;
    margin-bottom: 10px;
    position: relative;
    overflow-y: scroll; }

.cart {
  padding: 15px; }
  .cart h6 {
    font-family: "BenderRegular";
    color: #4f5256;
    font-size: 16px; }
  .cart .cart__price {
    font-family: "BenderBold";
    margin-top: 15px;
    margin-bottom: 0; }
    .cart .cart__price span {
      color: #a80505; }
  .cart .panel {
    border-radius: 0; }
  .cart__qty {
    font-family: "BenderBold" !important; }
    .cart__qty span {
      display: inline-block;
      margin-left: 11px;
      color: #a80505;
      font-family: "BenderBold";
      font-size: 18px; }
  .cart__btn-qty, .cart__btn-qty:hover {
    color: #a80505;
    border-color: #a80505;
    outline: none;
    outline-offset: 0;
    margin: 0 7px;
    border-radius: 0; }
  .cart .btn-danger {
    font-family: "BenderBold";
    margin-top: 10px;
    background-color: #a80505;
    border-color: #a80505;
    border-radius: 0; }
  .cart__btn-order {
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
    line-height: 1.95rem;
    background: none;
    margin: 10px 0;
    padding: 8px 16px 4px 16px;
    outline: none;
    outline-offset: 0;
    border: 1px solid #a80505;
    color: #a80505;
    display: block;
    margin-top: 25px; }
  .cart__sum {
    font-family: "BenderBold" !important;
    font-size: 20px !important;
    margin-bottom: 25px; }
    .cart__sum span {
      color: #a80505; }
  .cart__phone-form {
    border: none;
    font-weight: "BenderRegular" !important;
    display: block;
    font-size: 3rem;
    font-weight: normal; }
  .cart__phone-label {
    display: block;
    font-weight: "BenderRegular" !important;
    font-size: 2rem;
    font-weight: normal; }
  .cart__order {
    border: 1px solid #a80505;
    padding: 10px;
    width: 400px; }
  .cart--empty {
    margin: 25px;
    color: #4f5256;
    font-family: "BenderBold";
    font-size: 2rem; }

@media (max-width: 767px) {
  .cart .panel {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    text-align: center;
    position: relative; }
  .cart__order {
    max-width: 100%; }
  .cart__phone-form {
    max-width: 100%;
    font-size: 2.3rem; }
  .cart__phone-label {
    font-size: 1.8rem; }
  .cart__btn--delete {
    display: none; }
  .cart__qty span {
    display: block;
    margin-left: 0;
    margin-top: 10px;
    font-size: 2.5rem; }
  .cart__btn-qty {
    font-size: 2.5rem;
    width: 60px; }
  .cart__delete-badge {
    background-color: #a80505;
    color: #fff;
    font-size: 1.8rem;
    font-family: "BenderBold";
    width: 100px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 10px;
    right: 10px; }
  .cart__sum-bage {
    background-color: #a80505;
    font-family: "BenderBold";
    color: #fff;
    position: fixed;
    z-index: 100;
    bottom: 30px;
    left: 10px;
    padding: 10px;
    opacity: .7; } }

.error-404 {
  margin: 10px; }
  .error-404 p {
    font-size: 1.9rem; }

.cart-modal__title {
  font-size: 2.5rem; }

.cart-modal .close {
  font-size: 3.5rem;
  color: #a80505;
  opacity: .5; }

.cart-modal__body-header {
  font-size: 2rem; }

.cart-modal__body-text {
  font-size: 1.8rem; }

.sort-container {
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  margin: 15px auto;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 10px 10px 15px 10px;
  color: #626262; }
  .sort-container .text {
    font-weight: 400; }
  .sort-container .sort-btn {
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border: 1px solid #939599;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    border-radius: 3px;
    margin-left: 8px;
    top: 9px;
    position: relative;
    width: 70px;
    height: 30px;
    color: #939599;
    font-size: 1.5rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .sort-container .sort-btn span {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -10px);
              transform: translate(-50%, -10px); }
    .sort-container .sort-btn .sort-arrow {
      font-size: 2rem;
      position: relative;
      top: -1px;
      left: 2px; }
  .sort-container .sort-btn.active {
    border: 1px solid #a80505;
    color: #a80505; }
  .sort-container .sort-btn__long {
    width: 110px; }

div.category-content {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.special-offer-list {
  background-color: #a80505; }

.special-offer-list-row .content__heading {
  color: #fff !important;
  margin: 40px auto; }

div.category_item {
  border: 2px solid #a80505; }
  div.category_item .item__title--sublist {
    color: #a80505;
    font-family: "Roboto", sans-serif;
    font-weight: normal; }

.link-to-special-offer {
  position: relative;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  height: 500px; }

.special-offer-list-row {
  margin: 0 !important; }

.link-to-special-offer span {
  display: block;
  text-align: center;
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  color: #a80505;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.link-to-special-offer span:hover {
  background-color: #a80505;
  color: #fff; }

@media (max-width: 767px) {
  div.category_item {
    background-color: #a80505; }
    div.category_item .item__title--sublist {
      font-size: 24px;
      color: #fff; } }

.items-table-container {
  font-family: "Roboto", sans-serif; }
  .items-table-container .items-tables__price {
    color: #a80505;
    font-weight: 500; }
  .items-table-container .item-table-row td {
    vertical-align: middle; }
  .items-table-container .items-table__title {
    width: 40%; }
  .items-table-container .item-table__btn {
    font-size: 1.1rem;
    background-color: #fff;
    text-transform: uppercase;
    border: 1px solid #a80505;
    border-radius: 3px;
    color: #a80505;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
    width: 80px; }
  .items-table-container .item-table__btn:hover {
    color: #fff;
    background-color: #a80505; }
  .items-table-container .item-table__btn--blue {
    border: 1px solid #3483bd;
    color: #3483bd; }
  .items-table-container .item-table__btn--blue:hover {
    color: #fff;
    background-color: #3483bd; }

.my-carousel {
  text-align: center;
  width: 100%;
  position: relative;
  padding-left: 50px;
  padding-right: 50px; }

.my-carousel-slides-all {
  width: 100%;
  height: 390px;
  margin: 0 auto;
  background-color: #a80505;
  overflow: hidden;
  position: relative; }

.my-carousel-slide {
  background-color: #a80505;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  align-content: stretch; }

.my-carousel-slide-item {
  width: 33.3%;
  padding: 0 5px;
  flex: 0 1 auto; }

.my-carousel-slide-item div.item {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #fff;
  border: none;
  cursor: pointer; }
  .my-carousel-slide-item div.item .item__btn {
    display: none; }

.my-carousel-slide-item div.item:hover {
  box-shadow: none; }

.my-carousel-btn {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #a80505;
  border-radius: 3px;
  color: #a80505;
  font-size: 40px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .my-carousel-btn svg {
    position: absolute;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .my-carousel-btn_left {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .my-carousel-btn_right {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .my-carousel-btn:hover {
    color: #fff;
    background-color: #a80505;
    border-color: #fff; }
  .my-carousel-btn:focus {
    outline: none; }

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.loader {
  color: #ae0f19;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0; }

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 3.5em; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }

.poduct-info {
  padding-top: 15px;
  padding-bottom: 15px; }

.poduct-info-top {
  padding-top: 15px;
  text-align: center; }
  .poduct-info-top .row {
    margin: 10px; }
  .poduct-info-top .poduct-info-img {
    width: 80%;
    max-width: 350px;
    margin: auto;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    overflow: hidden; }
    .poduct-info-top .poduct-info-img img {
      width: 100%;
      height: auto; }
  .poduct-info-top .poduct-info-images {
    list-style: none;
    margin: 15px auto;
    text-align: center;
    padding: 0; }
    .poduct-info-top .poduct-info-images li {
      display: inline-block;
      width: 90px;
      height: 90px;
      border: 1px solid #bdbdbd;
      border-radius: 3px;
      overflow: hidden;
      margin: 3px;
      cursor: pointer;
      -webkit-transition: all 0.4s;
      transition: all 0.4s; }
    .poduct-info-top .poduct-info-images li.active-img {
      border: 1px solid #a80505; }
    .poduct-info-top .poduct-info-images img {
      width: 100%;
      height: auto; }
  .poduct-info-top h2 {
    text-transform: uppercase; }
  .poduct-info-top .product-info-price {
    font-family: "BenderBold";
    color: #fff;
    font-size: 22px;
    width: 170px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    padding: 8px;
    margin: 10px auto;
    background-color: #a80505; }
    .poduct-info-top .product-info-price h2 {
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      color: #fff;
      margin: 0 auto; }
      .poduct-info-top .product-info-price h2 span {
        font-size: 20px; }
  .poduct-info-top h3 {
    color: #4f5256;
    margin-top: 30px;
    margin-bottom: 20px; }
  .poduct-info-top table td {
    font-size: 16px;
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .poduct-info-top hr {
    width: 90%; }
  .poduct-info-top .product-info-description-heading {
    text-align: left;
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
  .poduct-info-top .product-info-description {
    width: 90%;
    margin: auto;
    text-align: left;
    text-indent: 18px;
    font-size: 18px; }

