.sticky {
  width: 100%;
  height: auto;
  max-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 0 50px;
  opacity: 1;
  transition: all 0.5s;

  &--hidden {
    width: 100%;
    height: 75px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    padding: 0 50px;
  }

  &__logo {
    float: left;
    height: 75px;
  }

  &__address {
    color: #fff;
    margin: 4px auto;
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
  }
}

@media (max-width: $xs) {
  .sticky {
    padding: 0 10px;

    &__address {
      font-size: 1.2rem;
      line-height: 1.4rem;
      width: 200px;
      margin-top: 17px;
    }

    &__logo {
      height: 50px;
      margin-top: 15px;
    }
  }

  .hero__icons {
    right: 20px !important;
    transform: scale(0.8);
  }
}
