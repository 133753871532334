html,
body {
  font-family: $mainFont;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $hFont;
  color: $mainRed;
}

.container {
  margin: 0;
  width: 100%;
}

.modal-content {
  border-radius: 0;
}

@media (max-width: $xs) {
  .padding-at-xs {
    margin-top: 50px;
  }
}

.content-container {
  width: 96%;
  margin: 0 auto;
}

.main-category-container {
  margin: 0 auto;
  padding: 0 15px;
}

a:hover {
  text-decoration: none !important;
}
