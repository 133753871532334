.my-carousel {
  text-align: center;
  width: 100%;
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
}

.my-carousel-slides-all {
  width: 100%;
  height: 390px;
  margin: 0 auto;
  background-color: $mainRed;
  // border: 1px solid black;
  overflow: hidden;
  position: relative;
}

.my-carousel-slide {
  background-color: $mainRed;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;

  align-content: stretch;
  // right: 0;
  // transition: left 1.5s cubic-bezier(0.42, 0, 0.58, 1);
}

.my-carousel-slide-item {
  width: 33.3%;
  padding: 0 5px;
  flex: 0 1 auto;
}

.my-carousel-slide-item div.item {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #fff;
  border: none;
  cursor: pointer;

  .item__btn {
    display: none;
  }
}

.my-carousel-slide-item div.item:hover {
  box-shadow: none;
}

.my-carousel-btn {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: 1px solid $mainRed;
  border-radius: 3px;
  color: $mainRed;
  font-size: 40px;
  text-align: center;
  transition: all 0.3s;

  svg {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &_left {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &_right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &:hover {
    color: #fff;
    background-color: $mainRed;
    border-color: #fff;
  }

  &:focus {
    outline: none;
  }
}
//
// .my-carousel-slide:nth-of-type(1) {
//   background-color: yellow;
// }
//
// .my-carousel-slide:nth-of-type(2) {
//   background-color: tomato;
// }
//
// .my-carousel-slide:nth-of-type(3) {
//   background-color: rgb(115, 233, 42);
// }
//
// .my-carousel-slide:nth-of-type(4) {
//   background-color: rgb(42, 187, 233);
// }
